import { useState, useEffect, useContext } from "react";
import { fetchAsObservable } from "fetcher!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { pluck } from "rxjs/operators";
import { handleError } from "src/handle-error";
import { ClientContext } from "../client-portal.context.js";
import { QUESTIONNAIRE_TYPES } from "./questionnaire/questionnaire-types.constant.js";

export function useTodosApi() {
  const { selectedClient } = useContext(ClientContext);

  const [todoData, setTodoData] = useState({
    loading: true,
    todos: [],
  });

  useEffect(() => {
    const todos$ = fetchAsObservable(
      `/api/clients/${selectedClient.id}/todos`
    ).subscribe((response) => {
      let filteredTodos = response?.todos;
      if (!featureEnabled("toggle_custom_forms")) {
        filteredTodos = filteredTodos.filter(
          (t) => t.tool_type !== QUESTIONNAIRE_TYPES.QUESTIONNAIRE.type
        );
      }
      if (!featureEnabled("toggle_document_checklist")) {
        filteredTodos = filteredTodos.filter(
          (t) => t.tool_type !== QUESTIONNAIRE_TYPES.DOCUMENT_CHECKLIST.type
        );
      }
      if (featureEnabled("toggle_document_checklist")) {
        // TODO: remove this once the document checklist is implemented
        const activeQNR = filteredTodos.find(
          (t) =>
            t.tool_type === QUESTIONNAIRE_TYPES.QUESTIONNAIRE.type &&
            t.status === "WITH_CLIENT"
        );
        const mockDocumentChecklist = {
          ...activeQNR,
          tool_type: QUESTIONNAIRE_TYPES.DOCUMENT_CHECKLIST.type,
          title: "Doc Checklist Mock",
        };
        if (activeQNR) {
          filteredTodos = [mockDocumentChecklist, ...filteredTodos];
        }
      }
      setTodoData({
        loading: false,
        todos: filteredTodos || [],
      });
    }, handleError);

    return () => todos$.unsubscribe();
  }, [selectedClient.id]);

  return todoData;
}

export function getEsignFile(fileId, logView) {
  const _logView = logView ? "?log_view=true" : "";
  return fetchAsObservable(`/api/docs/esign-docs/${fileId}${_logView}`)
    .pipe(pluck("esign_doc"))
    .toPromise();
}

export function getResolutionCaseEsign(letterId, esignDocId) {
  return fetchAsObservable(
    `/api/letters/${letterId}/esign_docs/${esignDocId}/signing_requests`
  )
    .pipe(pluck("signing_requests"))
    .toPromise();
}

export function getEngagementPdf(engagementId, userId) {
  return fetchAsObservable(
    `/api/engagements/${engagementId}/signer/${userId}/pdf`
  );
}
