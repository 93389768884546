import React, { useContext, useMemo } from "react";
import { get } from "lodash";
import { useCss } from "kremling";
import {
  CpArea,
  CpButton,
  CpCard,
  CpIcon,
  CpLoader,
  CpWell,
} from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import PageTitle from "@common/page-title.component";
import { ViewPaymentMethod } from "./view-payment-method.component";
import { CreateEditPaymentMethod } from "./create-edit-payment-method.component";
import { ClientContext } from "src/client-portal.context";
import { BillingContext, PaymentContext } from "../billing/billing-context";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { usePayments } from "../billing/create-edit-payment/payment.hooks";
import {
  cardIconNames,
  getPaymentMethodName,
  dateFromCardExpiry,
  isExpiredCard,
  updateBankAccount,
  updateCard,
  updateCanopyPayment,
  paymentViewMode,
} from "../billing/create-edit-payment/create-edit-payment.helper";

import css from "./payment-settings.krem.css";

export default function PaymentSettings(props) {
  const scope = useCss(css);

  const { selectedClient } = useContext(ClientContext);
  const { amexEnabled, hasAdyen } = useContext(BillingContext);
  const { step, paymentDetails, status, actions, paysafeInstance } =
    usePayments({
      clientId: selectedClient.id,
      amexEnabled,
      hasAdyen,
      paymentViewMode: paymentViewMode.Settings,
      onComplete: () => {},
    });

  const phoneOnly = useMediaQuery("--phone-only");

  const cards = useMemo(
    () =>
      paymentDetails.savedPaymentMethods?.filter(
        (method) => method.type === "savedCreditCard"
      ) || [],
    [paymentDetails.savedPaymentMethods]
  );
  const achs = useMemo(
    () =>
      paymentDetails.savedPaymentMethods?.filter(
        (method) => method.type === "savedACH"
      ) || [],
    [paymentDetails.savedPaymentMethods]
  );

  const setPreferredAccount = (accountId) => {
    updateBankAccount(accountId, { ach: { is_preferred: true } }).subscribe(
      () => {
        actions.refetchPaymentMethods();
      }
    );
  };

  const setPreferredCard = (cardId) => {
    updateCard(cardId, { card: { is_preferred: true } }).subscribe(() => {
      actions.refetchPaymentMethods();
    });
  };

  const setPreferredMethod = (methodId) => {
    updateCanopyPayment(methodId, { is_preferred: true }).subscribe(() => {
      actions.refetchPaymentMethods();
    });
  };

  const viewPaymentMethod = (method) => {
    actions.updatePaymentMethod(method);
    actions.setShowViewPaymentMethod(true);
  };

  return (
    <PaymentContext.Provider
      value={{ step, paymentDetails, status, actions, paysafeInstance }}
    >
      <div {...scope} className="page-content no-footer">
        {!phoneOnly && (
          <PageTitle
            fullSize
            left={<span className="title-text">Payment Settings</span>}
          />
        )}
        {status.showViewPaymentMethod ? (
          <ViewPaymentMethod />
        ) : status.showCreateEditPaymentMethod ? (
          <CreateEditPaymentMethod />
        ) : (
          <CpCard
            level={phoneOnly ? 0 : 2}
            className="cp-pv-32 cp-ph-24"
            style={
              phoneOnly
                ? { maxHeight: "calc(100vh - 69px)", overflowY: "auto" }
                : {}
            }
          >
            {status.loadingPaymentMethods ? (
              <CpLoader />
            ) : (
              <>
                <div className="cps-wt-bold cp-mb-8">Bank Accounts</div>
                {achs.length > 0 ? (
                  achs.map((ach) => (
                    <CpWell key={ach.id} level={2} className="cp-mb-12">
                      <CpArea
                        className="clickable-well"
                        onClick={() => viewPaymentMethod(ach)}
                      >
                        <div className="cp-pv-8 cp-ph-16 cp-flex-spread-center">
                          <div
                            style={{ overflow: "hidden" }}
                            className="cp-flex-center"
                          >
                            <div>
                              <CpButton
                                aria-label="Preferred account"
                                className="cp-mr-8"
                                icon={
                                  ach.is_preferred
                                    ? "shape-star-filled"
                                    : "shape-star-open"
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  !ach.is_preferred &&
                                    (hasAdyen
                                      ? setPreferredMethod(ach.id)
                                      : setPreferredAccount(ach.id));
                                }}
                              />
                            </div>
                            <div>
                              <CpIcon
                                className="cp-mr-16"
                                name="billing-check"
                              />
                            </div>
                            <div>
                              <div>{getPaymentMethodName(ach)}</div>
                              {ach.nickname && (
                                <div style={{ fontStyle: "italic" }}>
                                  {!hasAdyen &&
                                    ach.account_type[0].toUpperCase() +
                                      ach.account_type.substr(1).toLowerCase()}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>
                            <CpButton
                              aria-label="View"
                              btnType="icon"
                              icon={`caret-large-${
                                phoneOnly ? "right" : "down"
                              }`}
                              onClick={() => viewPaymentMethod(ach)}
                            />
                          </div>
                        </div>
                      </CpArea>
                    </CpWell>
                  ))
                ) : (
                  <CpWell
                    className="cp-p-16 cp-mb-12 cp-flex-center"
                    style={{ justifyContent: "center" }}
                    level={2}
                  >
                    No bank accounts have been saved on this account.
                  </CpWell>
                )}
                <CpButton
                  aria-label="Add bank account"
                  className="cp-mb-12"
                  btnType="flat"
                  icon="add-large"
                  onClick={() => {
                    actions.updatePaymentMethod({ type: "newACH" });
                    actions.setShowCreateEditPaymentMethod(true);
                    actions.updatePaymentSession();
                  }}
                >
                  Add bank account
                </CpButton>
                <div className="cps-wt-bold cp-mb-8">
                  {featureEnabled("toggle_gs_display_debit_type")
                    ? "Credit/Debit"
                    : "Credit"}{" "}
                  Cards
                </div>
                {cards.length > 0 ? (
                  cards.map((card) => {
                    const expiration = card.card_expiry
                      ? dateFromCardExpiry(card.card_expiry)
                      : undefined;
                    const isExpired = isExpiredCard(card);
                    const validCardType = card.cardType
                      ? status.validCardTypes.includes(card.cardType)
                      : true;
                    return (
                      <CpWell key={card.id} className="cp-mb-12" level={2}>
                        <CpArea
                          className="clickable-well"
                          onClick={() => viewPaymentMethod(card)}
                        >
                          <div className="cp-pv-8 cp-ph-16 cp-flex-spread-center">
                            <div
                              style={{ overflow: "hidden" }}
                              className="cp-flex-center"
                            >
                              <div>
                                <CpButton
                                  aria-label="Preferred credit/debit card"
                                  className="cp-mr-8"
                                  icon={
                                    card.is_preferred
                                      ? "shape-star-filled"
                                      : "shape-star-open"
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    !card.is_preferred &&
                                      (hasAdyen
                                        ? setPreferredMethod(card.id)
                                        : setPreferredCard(card.id));
                                  }}
                                  disabled={isExpired || !validCardType}
                                />
                              </div>
                              <div>
                                <CpIcon
                                  className="cp-mr-16"
                                  name={get(
                                    cardIconNames,
                                    card.card_type,
                                    "billing-credit-card"
                                  )}
                                />
                              </div>
                              <div>
                                <div>{getPaymentMethodName(card)}</div>
                                {validCardType ? (
                                  <div style={{ fontStyle: "italic" }}>
                                    {isExpired ? (
                                      <span className="cps-color-warning">
                                        Expired {expiration.toFormat("MM/yyyy")}
                                      </span>
                                    ) : (
                                      <span>
                                        Expires {expiration.toFormat("MM/yyyy")}
                                      </span>
                                    )}
                                  </div>
                                ) : (
                                  <div style={{ fontStyle: "italic" }}>
                                    <span className="cps-color-warning">
                                      AMEX no longer supported
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div>
                              <CpButton
                                aria-label="View"
                                btnType="icon"
                                icon={`caret-large-${
                                  phoneOnly ? "right" : "down"
                                }`}
                                onClick={() => viewPaymentMethod(card)}
                              />
                            </div>
                          </div>
                        </CpArea>
                      </CpWell>
                    );
                  })
                ) : (
                  <CpWell
                    className="cp-p-16 cp-mb-12 cp-flex-center"
                    style={{ justifyContent: "center" }}
                    level={2}
                  >
                    No{" "}
                    {featureEnabled("toggle_gs_display_debit_type")
                      ? "credit/debit"
                      : "credit"}{" "}
                    cards have been saved on this account.
                  </CpWell>
                )}
                <CpButton
                  aria-label="Add credit/debit card"
                  btnType="flat"
                  icon="add-large"
                  onClick={() => {
                    actions.updatePaymentMethod({ type: "newCreditCard" });
                    actions.setShowCreateEditPaymentMethod(true);
                    actions.updatePaymentSession();
                  }}
                >
                  Add{" "}
                  {featureEnabled("toggle_gs_display_debit_type")
                    ? "credit/debit"
                    : "credit"}{" "}
                  card
                </CpButton>
              </>
            )}
          </CpCard>
        )}
      </div>
    </PaymentContext.Provider>
  );
}
