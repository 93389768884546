import React, { lazy } from "react";
import { pluck } from "rxjs/operators";
import { DateTime } from "luxon";
import routingNumValidator from "bank-routing-number-validator";
import { fetchAsObservable } from "fetcher!sofe";
import { featureEnabled } from "feature-toggles!sofe";

export const paysafeCardTypes = {
  VI: "Visa",
  MC: "MasterCard",
  AM: "American Express",
  DI: "Discover",
};
export const cardIconNames = {
  VI: "billing-visa",
  MC: "billing-mastercard",
  AM: "billing-amex",
  DI: "billing-discover",
};

export const paymentSteps = {
  SelectInvoices: 0,
  PaymentDetails: 1,
  ConfirmDetails: 2,
  Processing: 3,
  Receipt: 4,
  Error: 5,
};

export const paymentViewMode = {
  Settings: "settings",
  Payments: "payments",
};

export const paymentErrors = {
  declinedCard: {
    title: "Payment Declined",
    subject: "This credit card was declined for the following reason(s):",
    details: "",
  },
  failedGeneric: {
    title: "Payment Failed",
    subject: "This payment could not be processed for the following reason(s):",
    details: "",
  },
  saveCard: {
    title: "Payment Failed",
    subject: "Unable to save credit card:",
    details: "",
  },
  saveBank: {
    title: "Payment Failed",
    subject: "Unable to save bank account:",
    details: "",
  },
};

export const paymentStepConfig = [
  {
    // Select Invoices
    title: (isEdit) => (isEdit ? "Edit a Payment" : "Make a Payment"),
    validate: ({ paymentDetails }) => paymentDetails.paymentTotal > 0,
    nextButton: () => "Continue",
  },
  {
    // Payment Details
    title: (isEdit) => (isEdit ? "Edit a Payment" : "Make a Payment"),
    validate: ({
      paymentDetails,
      invalidFields,
      validCardTypes,
      paysafeInstance,
      hasAdyen,
    }) => {
      const { paymentMethod, creditCardData } = paymentDetails;
      const validCardType = paymentMethod.type.includes("CreditCard")
        ? creditCardData.validCardType
        : true;
      if (hasAdyen && paymentDetails.adyenInstance) {
        if (
          paymentDetails.savedMethod &&
          !paymentDetails.savedMethodName.trim()
        )
          return false;
        return true;
      }
      if (
        paymentMethod.type.includes("saved") &&
        !isExpiredCard(paymentMethod) &&
        validCardType
      ) {
        return true;
      } else {
        return isValidPayment(paymentDetails, paysafeInstance) && validCardType;
      }
    },
    prevButton: () => "Back",
    nextButton: () => "Continue",
  },
  {
    // Confirm Details
    title: (isEdit, isFuturePayment) =>
      isEdit
        ? "Confirm Changes"
        : isFuturePayment
        ? "Verify and Schedule Payment"
        : "Verify and Confirm Payment",
    validate: () => true,
    prevButton: () => "Back",
    nextButton: (isFuturePayment) =>
      isFuturePayment ? "Schedule payment" : "Confirm and pay",
  },
  {
    //Processing
    title: () => "Processing...",
  },
  {
    // Receipt
    title: (isEdit, isFuturePayment) =>
      isEdit
        ? "Payment Modified"
        : isFuturePayment
        ? "Payment Scheduled"
        : "Payment Submitted",
    validate: () => true,
    nextButton: () => "Done",
  },
  {
    // Error
    title: () => "",
    validate: () => true,
    nextButton: () => "Edit payment details",
  },
];

export const dateFromCardExpiry = ({ month, year }) => {
  return DateTime.fromObject({ month, year });
};

export const isExpiredCard = (paymentMethod) => {
  return (
    paymentMethod.card_expiry &&
    dateFromCardExpiry(paymentMethod.card_expiry) <
      DateTime.local().startOf("month")
  );
};

export const isValidPayment = (paymentDetails, paysafeInstance) => {
  const { paymentMethod, creditCardData, achData } = paymentDetails;
  if (paymentMethod.type.includes("CreditCard")) {
    return (
      paysafeInstance?.areAllFieldsValid() &&
      !!creditCardData.name &&
      !!creditCardData.street &&
      !!creditCardData.city &&
      !!creditCardData.state &&
      creditCardData.zip.length === 5
    );
  } else {
    return (
      achData.routingNumber.length === 9 &&
      routingNumValidator.ABARoutingNumberIsValid(achData.routingNumber) &&
      achData.accountNumber.length >= 4 &&
      achData.confirmAccountNumber === achData.accountNumber &&
      achData.firstName.length &&
      achData.lastName.length &&
      achData.street.length &&
      achData.city.length &&
      achData.state.length === 2 &&
      achData.zip.length === 5
    );
  }
};

export const ccDefault = {
  id: null,
  number: "",
  cursorPos: 0,
  type: "Unknown",
  expMonth: "",
  expYear: "",
  cvv: "",
  name: "",
  zip: "",
  state: "",
  city: "",
  street: "",
  street2: "",
  saveCard: false,
  isExpired: false,
  isPreferred: false,
  authorizedUse: false,
  nickname: "",
  expirationDate: DateTime.local(),
  cardType: "",
  validCardType: true,
  editMode: false,
};

export const achDefault = {
  accountType: "checking",
  routingNumber: "",
  accountNumber: "",
  confirmAccountNumber: "",
  name: "",
  firstName: "",
  lastName: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  saveAccount: false,
  isPreferred: false,
  authorizedUse: false,
  nickname: "",
  editMode: false,
};

export const states = [
  { key: "AL", value: "AL" },
  { key: "AK", value: "AK" },
  { key: "AS", value: "AS" },
  { key: "AZ", value: "AZ" },
  { key: "AR", value: "AR" },
  { key: "AA", value: "AA" },
  { key: "AE", value: "AE" },
  { key: "AP", value: "AP" },
  { key: "CA", value: "CA" },
  { key: "CO", value: "CO" },
  { key: "CT", value: "CT" },
  { key: "DE", value: "DE" },
  { key: "DC", value: "DC" },
  { key: "FL", value: "FL" },
  { key: "GA", value: "GA" },
  { key: "HI", value: "HI" },
  { key: "ID", value: "ID" },
  { key: "IL", value: "IL" },
  { key: "IN", value: "IN" },
  { key: "IA", value: "IA" },
  { key: "KS", value: "KS" },
  { key: "KY", value: "KY" },
  { key: "LA", value: "LA" },
  { key: "ME", value: "ME" },
  { key: "MD", value: "MD" },
  { key: "MA", value: "MA" },
  { key: "MI", value: "MI" },
  { key: "MN", value: "MN" },
  { key: "MS", value: "MS" },
  { key: "MO", value: "MO" },
  { key: "MT", value: "MT" },
  { key: "NE", value: "NE" },
  { key: "NV", value: "NV" },
  { key: "NH", value: "NH" },
  { key: "NJ", value: "NJ" },
  { key: "NM", value: "NM" },
  { key: "NY", value: "NY" },
  { key: "NC", value: "NC" },
  { key: "ND", value: "ND" },
  { key: "MP", value: "MP" },
  { key: "OH", value: "OH" },
  { key: "OK", value: "OK" },
  { key: "OR", value: "OR" },
  { key: "PW", value: "PW" },
  { key: "PA", value: "PA" },
  { key: "PR", value: "PR" },
  { key: "RI", value: "RI" },
  { key: "SC", value: "SC" },
  { key: "SD", value: "SD" },
  { key: "TN", value: "TN" },
  { key: "TX", value: "TX" },
  { key: "VI", value: "VI" },
  { key: "UT", value: "UT" },
  { key: "VT", value: "VT" },
  { key: "VA", value: "VA" },
  { key: "WA", value: "WA" },
  { key: "WV", value: "WV" },
  { key: "WI", value: "WI" },
  { key: "WY", value: "WY" },
];

export const getPaymentMethodName = (
  method,
  isExpired,
  validCardType = true
) => {
  if (method.type.includes("new")) {
    if (method.type.includes("CreditCard")) {
      return (
        <span>
          {featureEnabled("toggle_gs_display_debit_type")
            ? "Credit/Debit"
            : "Credit"}{" "}
          Card
        </span>
      );
    } else {
      return <span>Bank Account</span>;
    }
  }
  return (
    <div>
      <div>
        <span>
          {method.nickname ||
            method.cardType ||
            (method.account_type &&
              method.account_type[0].toUpperCase() +
                method.account_type.substr(1).toLowerCase())}
        </span>
        <span className="cp-ml-4">*{method.last_two || method.last_four}</span>
      </div>
      <div className="cp-color-app-error-text">
        {isExpired && "Expired"}
        {isExpired && !validCardType && " - "}
        {!validCardType && "AMEX no longer supported"}
      </div>
    </div>
  );
};

export const separateName = (name) => {
  const names = name.split(" ");
  return {
    first_name: names[0],
    last_name: names.length > 1 ? names[names.length - 1] : "",
  };
};

export const maybeSaveCard = (paymentDetails, token) => {
  return new Promise((resolve, reject) => {
    const { clientId, creditCardData, paymentMethod, makeDefault, saveCard } =
      paymentDetails;
    if (saveCard) {
      const card = {
        cardDetails: {
          ...separateName(creditCardData.name),
        },
        cc_token: token,
        is_preferred: makeDefault,
        team_can_use: paymentMethod.authorizedUse,
        nickname: paymentMethod.nickname || "",
        relationships: { for: { client_id: Number(clientId) } },
      };

      if (paymentMethod.type.includes("new")) {
        createCard({ card }).subscribe(resolve, reject);
      } else {
        updateCard(paymentMethod.id, { card }).subscribe(resolve, reject);
      }
    } else {
      resolve(token);
    }
  });
};

export const maybeSaveAccount = (paymentDetails) => {
  const { clientId, achData, paymentMethod, makeDefault, saveCard } =
    paymentDetails;
  const ach = {
    ach_details: {
      account_holder_name: achData.name,
      account_number: achData.accountNumber,
      account_type: achData.accountType.toUpperCase(),
      routing_number: achData.routingNumber,
      first_name: achData.firstName,
      last_name: achData.lastName,
      street: achData.street,
      street2: achData.street2,
      city: achData.city,
      state: achData.state,
      zip: achData.zip,
      country: "US",
    },
    is_preferred: makeDefault,
    team_can_use: paymentMethod.authorizedUse,
    nickname: paymentMethod.nickname || "",
    relationships: { for: { client_id: Number(clientId) } },
  };

  return new Promise((resolve, reject) => {
    if (saveCard) {
      createBankAccount({ ach }).subscribe(
        (card) => resolve({ ...ach.ach_details, id: card.id }),
        reject
      );
    } else {
      resolve(ach.ach_details);
    }
  });
};

export const updateExistingCard = (replaceDetails, paymentDetails, token) => {
  const { creditCardData, paymentMethod, makeDefault } = paymentDetails;

  const card = {
    card_details: replaceDetails
      ? {
          ...separateName(creditCardData.name),
        }
      : undefined,
    cc_token: token,
    is_preferred: makeDefault,
    team_can_use: paymentMethod.authorizedUse,
    nickname: paymentMethod.nickname || "",
  };

  return updateCard(paymentMethod.id, { card });
};

export const updateExistingAccount = (replaceDetails, paymentDetails) => {
  const { achData, paymentMethod, makeDefault } = paymentDetails;

  const ach = {
    ach_details: replaceDetails
      ? {
          account_holder_name: achData.name,
          account_number: achData.accountNumber,
          account_type: achData.accountType.toUpperCase(),
          routing_number: achData.routingNumber,
          ...separateName(achData.name),
          street: achData.street,
          street2: achData.street2,
          city: achData.city,
          state: achData.state,
          zip: achData.zip,
        }
      : undefined,
    is_preferred: makeDefault,
    team_can_use: paymentMethod.authorizedUse,
    nickname: paymentMethod.nickname || "",
  };

  return updateBankAccount(paymentMethod.id, { ach });
};

export const prepareAndCreatePayment = (paymentDetails, users) => {
  const {
    clientId,
    invoices,
    isFuturePayment,
    isScheduledPayment,
    paymentId,
    paymentTotal,
    paymentDate,
    paymentMethod,
    token,
    card,
    creditCardData,
    bankAccount,
  } = paymentDetails;

  const payment = {};

  payment.amount = paymentTotal.toString();
  payment.check_number = "";
  payment.date = isFuturePayment ? paymentDate.toJSDate() : Date.now();
  payment.notes = "";

  payment.relationships = {
    for: { id: Number(clientId), type: "client" },
  };

  payment.invoice_payments = invoices
    .filter((invoice) => invoice.selected)
    .map((invoice) => ({
      amount: invoice.amountToPay.toString(),
      relationships: {
        applied_to: { id: invoice.id, type: "invoice" },
      },
    }));

  if (paymentMethod.type === "newCreditCard") {
    if (card.id) {
      payment.card_id = card.id;
    } else {
      payment.cc_token = token;
    }
    payment.method = "canopy_cc";
    payment.display_method = "Credit Card";
    const names = separateName(creditCardData.name);
    payment.first_name = names.first_name;
    payment.last_name = names.last_name;
  } else if (paymentMethod.type === "newACH") {
    payment.method = "canopy_dd";
    payment.display_method = "ACH";
    payment.ach_details = bankAccount;
  } else if (paymentMethod.type === "savedCreditCard") {
    payment.card_id = paymentMethod.id;
    payment.method = "canopy_cc";
    payment.display_method = "Credit Card";
  } else if (paymentMethod.type === "savedACH") {
    payment.card_id = paymentMethod.id;
    payment.method = "canopy_dd";
    payment.display_method = "ACH";
  }

  if (users) {
    payment.notifications = {
      users: users,
      url: `${
        window.location.origin
      }/m/clients/${clientId}/billing/payment/${encodeURI("{paymentId}")}`,
    };
  }

  payment.event_origin = "client_portal";

  const scheduledPayment =
    isFuturePayment || isScheduledPayment
      ? {
          description: "one-time",
          start_date: paymentDate.toISODate(),
          template: {
            amount: paymentTotal,
            card_id:
              paymentMethod.type === "newCreditCard"
                ? card.id
                : paymentMethod.type === "newACH"
                ? bankAccount.id
                : paymentMethod.id,
            client_notes: payment.notes,
            invoice_payments: payment.invoice_payments,
            relationships: payment.relationships,
          },
        }
      : {};

  return new Promise((resolve, reject) => {
    if (paymentId) {
      if (isFuturePayment || isScheduledPayment) {
        saveScheduledPayment(paymentId, {
          recurrences: scheduledPayment,
        }).subscribe(resolve, reject);
      } else {
        savePayment(paymentId, { payments: payment }).subscribe(
          resolve,
          reject
        );
      }
    } else if (isFuturePayment) {
      schedulePayment({ recurrences: scheduledPayment }).subscribe(
        resolve,
        reject
      );
    } else {
      createPayment({ payments: payment }).subscribe(resolve, reject);
    }
  });
};

export const createCard = (card) => {
  return fetchAsObservable("/api/card_management/card", {
    method: "POST",
    body: card,
  }).pipe(pluck("card"));
};

export const createBankAccount = (account) => {
  return fetchAsObservable("/api/card_management/ach", {
    method: "POST",
    body: account,
  }).pipe(pluck("card"));
};

export const updateCard = (cardId, card) => {
  return fetchAsObservable(`/api/card_management/card/${cardId}`, {
    method: "PATCH",
    body: card,
  }).pipe(pluck("card"));
};

export const updateBankAccount = (accountId, account) => {
  return fetchAsObservable(`/api/card_management/ach/${accountId}`, {
    method: "PATCH",
    body: account,
  }).pipe(pluck("card"));
};

export const deleteCard = (cardId) => {
  return fetchAsObservable(`/api/card_management/card/${cardId}`, {
    method: "DELETE",
  });
};

export const deleteBankAccount = (accountId) => {
  return fetchAsObservable(`/api/card_management/ach/${accountId}`, {
    method: "DELETE",
  });
};

export const deleteAdyenPaymentMethod = (paymentMethodId) => {
  return fetchAsObservable(`/api/card_management/${paymentMethodId}`, {
    method: "DELETE",
  });
};

export const createPayment = (payment) => {
  return fetchAsObservable("/api/payments", {
    method: "POST",
    body: payment,
  }).pipe(pluck("payments"));
};

export const savePayment = (id, payment) => {
  return fetchAsObservable(`/api/payments/${id}`, {
    method: "PUT",
    body: payment,
  }).pipe(pluck("payments"));
};

export const schedulePayment = (payment) => {
  return fetchAsObservable("/api/payments/scheduled_one_off", {
    method: "POST",
    body: payment,
  }).pipe(pluck("recurrences"));
};

export const saveScheduledPayment = (id, scheduledPayment) => {
  return fetchAsObservable(`/api/payments/scheduled_one_off/${id}`, {
    method: "PUT",
    body: scheduledPayment,
  }).pipe(pluck("recurrences"));
};

export const deleteScheduledPayment = (paymentId) => {
  return fetchAsObservable(`/api/payments/scheduled_one_off/${paymentId}`, {
    method: "DELETE",
  });
};

export function updatePaymentSession(payment) {
  return fetchAsObservable("/api/canopy_payments/payment_session", {
    method: "PUT",
    body: payment,
  });
}

export function processPaymentSession(paymentSessionId, paymentData) {
  return fetchAsObservable(
    `/api/canopy_payments/payment_session/${paymentSessionId}/process`,
    {
      method: "POST",
      body: paymentData,
    }
  );
}

export const updateCanopyPayment = (cardId, paymentData) => {
  return fetchAsObservable(`/api/card_management/${cardId}`, {
    method: "PATCH",
    body: paymentData,
  });
};

export function getSurchargeFee(paymentData) {
  return fetchAsObservable("/api/canopy_payments/calculate_fees", {
    method: "POST",
    body: paymentData,
  });
}

export function updateSurchargeOptions(surchargeOptions) {
  return fetchAsObservable("/api/canopy_payments/surcharge", {
    method: "PATCH",
    body: surchargeOptions,
  });
}

export const AdyenPaymentForm = lazy(() =>
  SystemJS.import("billing-ui!sofe")
    .then((bui) => {
      if (bui.getAdyenPaymentForm) {
        return bui.getAdyenPaymentForm();
      } else {
        return PaymentFormLoadFailure;
      }
    })
    .then((comp) => ({ default: comp }))
);

function PaymentFormLoadFailure() {
  return (
    <div>
      Payment form failed to load. Please try again or contact support if issue
      persists.
    </div>
  );
}
