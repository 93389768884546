import { useState, useEffect, useMemo } from "react";
import { find, get, pull } from "lodash";
import { DateTime } from "luxon";
import useForceUpdate from "use-force-update";
import routingNumValidator from "bank-routing-number-validator";
import { featureEnabled } from "feature-toggles!sofe";
import { fetcher } from "fetcher!sofe";
import { successToast } from "toast-service!sofe";
import {
  useClientUsers,
  useInvoices,
  useScheduledPayment,
} from "../billing-hooks";
import { convertStringToFloat, isProd } from "../billing-helpers";
import { handleError } from "src/handle-error";
import { genQueryKey, invalidateEntity, useQuery } from "src/react-query";
import {
  paymentSteps,
  paymentErrors,
  prepareAndCreatePayment,
  ccDefault,
  achDefault,
  paysafeCardTypes,
  maybeSaveCard,
  maybeSaveAccount,
  updateExistingCard,
  updateExistingAccount,
  isExpiredCard,
  deleteCard,
  deleteBankAccount,
  deleteAdyenPaymentMethod,
  deleteScheduledPayment,
  states,
  updatePaymentSession,
  processPaymentSession,
  updateCanopyPayment,
  saveScheduledPayment,
} from "./create-edit-payment.helper";

export function usePayments({
  clientId,
  invoiceId,
  paymentId,
  amexEnabled,
  hasAdyen,
  paymentViewMode,
  onComplete,
  surchargeEnabled,
  show,
}) {
  const [step, setStep] = useState(paymentSteps.SelectInvoices);
  const [clientUsers, setClientUsers] = useState();
  const [invoices, setInvoices] = useState();
  const [additionalPayment, setAdditionalPayment] = useState("");
  const [prevAdditionalPayment, setPrevAdditionalPayment] = useState("");
  const [additionalPaymentEnabled, setAdditionalPaymentEnabled] =
    useState(false);
  const [savedPaymentMethods, setSavedPaymentMethods] = useState();
  const [paymentMethod, setPaymentMethod] = useState({ type: "newCreditCard" });
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({
    type: "newCreditCard",
  });
  const [paymentNumber, setPaymentNumber] = useState();
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [paymentDate, setPaymentDate] = useState(
    DateTime.local().startOf("day")
  );
  const [creditNumber, setCreditNumber] = useState();
  const [paymentError, setPaymentError] = useState(paymentErrors.declinedCard);
  const [showViewPaymentMethod, setShowViewPaymentMethod] = useState(false);
  const [showCreateEditPaymentMethod, setShowCreateEditPaymentMethod] =
    useState(false);

  const [creditCardData, creditCardDataChanged] = useState(ccDefault);
  const [achData, achDataChanged] = useState(achDefault);
  const [validCardTypes, setValidCardTypes] = useState();
  const [invalidFields, setInvalidFields] = useState([]);
  const [saveMethod, setSaveMethod] = useState(false);
  const [makeDefault, setMakeDefault] = useState(false);

  const [adyenSessionDetails, setAdyenSessionDetails] = useState();
  const [adyenInstance, setAdyenInstance] = useState();
  const [savedMethod, setSavedMethod] = useState(false);
  const [savedMethodName, setSavedMethodName] = useState("");
  const [surchargeFee, setSurchargeFee] = useState();
  const [shouldSurcharge, setShouldSurcharge] = useState(surchargeEnabled);

  const [paysafeInstance, setPaysafeInstance] = useState(null);
  const forceUpdate = useForceUpdate();

  const {
    results: allUsers,
    loading: loadingClientUsers,
    error: clientUsersError,
    resubscribe: refetchClientUsers,
  } = useClientUsers(clientId);

  useEffect(() => {
    if (!allUsers.length) return;

    setClientUsers(
      allUsers
        .filter((user) => user.role === "Client")
        .map((client) => client.id)
    );
  }, [allUsers]);

  useEffect(() => {
    setValidCardTypes([
      "Visa",
      "MasterCard",
      "Discover",
      ...(amexEnabled ? ["American Express"] : []),
    ]);
  }, [amexEnabled]);

  const { results: payment } = useScheduledPayment(paymentId);

  useEffect(() => {
    if (payment?.next_occurrence) {
      handleUpdatePaymentDate(
        DateTime.fromISO(payment.next_occurrence).startOf("day")
      );
    }
    if (!!+payment?.template?.credit_amount) {
      handleToggleAdditionalPayment(true);
      handleUpdateAdditionalPayment(payment.template.credit_amount);
    }

    payment?.template &&
      setShouldSurcharge(
        surchargeEnabled || payment?.template?.is_surcharge || false
      );
  }, [payment]);

  const {
    results: { invoices: allInvoices },
    loading: loadingInvoices,
    error: invoiceError,
    resubscribe: refetchInvoices,
  } = useInvoices(clientId, false, show);

  useEffect(() => {
    if (allInvoices.length === 0) {
      setInvoices([]);
    } else {
      setInvoices(
        allInvoices
          .map((invoice) => {
            if (!invoiceId && !paymentId) {
              const selected = true;
              const amountToPay = invoice.balance;
              return { ...invoice, selected, amountToPay };
            } else if (invoiceId) {
              const selected = invoice.id.toString() === invoiceId.toString();
              const amountToPay = selected ? invoice.balance : 0;
              return { ...invoice, selected, amountToPay };
            } else {
              const selected =
                find(payment.invoices, { id: invoice.id }) || false;
              const amountToPay = selected?.payment_amount || 0;
              return { ...invoice, selected, amountToPay };
            }
          })
          .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1))
      );
    }
  }, [allInvoices, payment]);

  const {
    results: allPaymentMethods,
    loading: loadingPaymentMethods,
    error: paymentMethodError,
    resubscribe: refetchPaymentMethods,
  } = usePaymentMethods(clientId, show);

  useEffect(() => {
    if (loadingPaymentMethods) {
      return;
    } else if (allPaymentMethods?.length === 0) {
      setSavedPaymentMethods([]);
    } else {
      let matchedMethod;
      const allowedMethods = allPaymentMethods.filter((method) => {
        return method.cardType
          ? validCardTypes.includes(method.cardType) && !isExpiredCard(method)
          : true;
      });
      allowedMethods.forEach((method) => {
        if (method.id === payment?.template?.card_id) {
          matchedMethod = method;
        }
        if (!matchedMethod && method.is_preferred) {
          matchedMethod = method;
        }
      });

      const selectedMethod = matchedMethod ||
        allowedMethods?.[0] || { type: "newCreditCard" };

      setPaymentMethod(selectedMethod);
      setDefaultPaymentMethod(selectedMethod);

      allPaymentMethods.sort((a, b) =>
        a.id === selectedMethod?.id ? -1 : b.id === selectedMethod?.id ? 1 : 0
      );

      setSavedPaymentMethods(allPaymentMethods);
    }
  }, [allPaymentMethods, payment]);

  useEffect(() => {
    const total = invoices?.reduce(
      (sum, invoice) =>
        sum +
        (invoice.selected ? convertStringToFloat(invoice.amountToPay) : 0),
      +additionalPayment
    );
    setPaymentTotal(total);
  }, [invoices, additionalPayment]);

  const isFuturePayment = useMemo(
    () => paymentDate > DateTime.local().startOf("day"),
    [paymentDate]
  );
  const isScheduledPayment = useMemo(
    () => payment?.template?.scheduled_payment,
    [payment]
  );

  const clearAdyenPaymentInfo = () => {
    setSavedMethod(false);
    setSavedMethodName("");
    setMakeDefault(false);
    handleAuthorizeUse(false);
  };

  const handleNextStep = () => {
    if (step === paymentSteps.SelectInvoices) {
      if (hasAdyen) {
        handlePaymentSession();
        clearAdyenPaymentInfo();
      }
      setStep(step + 1);
    } else if (step === paymentSteps.ConfirmDetails) {
      setStep(paymentSteps.Processing);
      submitPayment();
    } else if (step === paymentSteps.Receipt) {
      onComplete();
      handleReset(500);
    } else if (step === paymentSteps.Error) {
      setStep(paymentSteps.PaymentDetails);
      setPaymentError(paymentErrors.declinedCard);
    } else {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    if (hasAdyen && step === paymentSteps.ConfirmDetails) {
      clearAdyenPaymentInfo();
    }
    setStep(step - 1);
  };

  const handleUpdateInvoices = (invoices) => {
    setInvoices(invoices);
  };

  const handleToggleAdditionalPayment = (value) => {
    setAdditionalPaymentEnabled(value);
    if (!value) {
      setPrevAdditionalPayment(additionalPayment);
      setAdditionalPayment("");
    }
    if (value && prevAdditionalPayment) {
      setAdditionalPayment(prevAdditionalPayment);
    }
  };

  const handleUpdateAdditionalPayment = (amount) => {
    setAdditionalPayment(amount);
  };

  const handleUpdatePaymentDate = (date) => {
    setPaymentDate(date);
  };

  const handleUpdatePaymentMethod = (method) => {
    (method.type === "newCreditCard" || method.type === "newACH") &&
      hasAdyen &&
      !isFuturePayment &&
      clearAdyenPaymentInfo();

    setPaymentMethod(method);
    setMakeDefault(method.is_preferred);
  };

  const handleUpdatePaysafeInstance = (instance) => {
    setPaysafeInstance(instance);
  };

  const handleUpdateNickname = (nickname) => {
    setPaymentMethod({ ...paymentMethod, nickname });
  };

  const handleAuthorizeUse = (authorizedUse) => {
    setPaymentMethod({ ...paymentMethod, authorizedUse });
  };

  const handlePaymentSession = () => {
    if (hasAdyen) {
      const selectedInvoices =
        invoices
          ?.filter((invoice) => invoice.selected)
          ?.map((invoice) => ({
            invoice_id: invoice.id,
            amount: invoice.amountToPay,
          })) || [];

      const sessionData = {
        ...(adyenSessionDetails?.payment_session_id && {
          payment_session_id: adyenSessionDetails?.payment_session_id,
        }),
        ...(paymentViewMode === "payments" &&
          !isScheduledPayment &&
          (selectedInvoices.length !== 0 || additionalPayment) && {
            session_details: {
              invoice_payments: selectedInvoices,
              account_balance_amount: additionalPayment || 0,
            },
          }),
        client_id: clientId,
      };

      updatePaymentSession(sessionData).subscribe((response) => {
        setAdyenSessionDetails(response);
      }, handleError);
    }
  };

  const handleSavePaymentMethod = (onComplete, setLoadingComplete) => {
    if (hasAdyen) {
      const paymentData = {
        ...(adyenInstance && {
          payment_data: { ...adyenInstance, storePaymentMethod: savedMethod },
        }),
        ...(savedMethodName && {
          nickname: savedMethodName.trim(),
        }),
        ...(paymentMethod.id && { card_id: paymentMethod.id }),
        team_can_use: paymentMethod.authorizedUse,
        is_preferred: makeDefault,
      };

      if (adyenSessionDetails?.payment_session_id) {
        processPaymentSession(
          adyenSessionDetails.payment_session_id,
          paymentData
        ).subscribe(
          () => {
            setAdyenSessionDetails();
            onComplete();
          },
          (error) => {
            handleError(error);
            setLoadingComplete();
          }
        );
      } else {
        updateCanopyPayment(paymentMethod.id, paymentData).subscribe(
          () => {
            setAdyenSessionDetails();
            onComplete();
          },
          (error) => {
            handleError(error);
            setLoadingComplete();
          }
        );
      }
      return;
    }

    if (paymentMethod.type === "newCreditCard") {
      const vaultConfig = {
        vault: {
          holderName: creditCardData.name,
          billingAddress: {
            street: creditCardData.street,
            street2: creditCardData.street2,
            city: creditCardData.city,
            state: creditCardData.state,
            zip: creditCardData.zip,
            country: "US",
          },
        },
      };

      let token;
      paysafeInstance.tokenize(vaultConfig, (instance, error, result) => {
        if (error) {
          setPaymentError({
            ...paymentErrors.declinedCard,
            details:
              error.code === "9003" && error.message.includes("expiry year")
                ? "Card Expired"
                : error.displayMessage,
          });
          setStep(paymentSteps.Error);
        } else {
          token = result.token;
          maybeSaveCard(paymentDetails, token)
            .then((card) => {
              onComplete(card);
            })
            .catch((error) => {
              setPaymentError({
                ...paymentErrors.saveCard,
                details:
                  get(error, "data.errors.message", "") ||
                  get(error, "data.errors.userMessage"),
              });
              setStep(paymentSteps.Error);
              setLoadingComplete();
            });
        }
      });
    } else if (paymentMethod.type === "newACH") {
      maybeSaveAccount(paymentDetails)
        .then((bankAccount) => {
          onComplete(bankAccount);
        })
        .catch((error) => {
          setPaymentError({
            ...paymentErrors.saveBank,
            details:
              get(error, "data.errors.message", "") ||
              get(error, "data.errors.userMessage"),
          });
          setStep(paymentSteps.Error);
          setLoadingComplete();
        });
    } else if (paymentMethod.type === "savedCreditCard") {
      if (creditCardData.editMode) {
        const vaultConfig = {
          vault: {
            holderName: creditCardData.name,
            billingAddress: {
              street: creditCardData.street,
              street2: creditCardData.street2,
              city: creditCardData.city,
              state: creditCardData.state,
              zip: creditCardData.zip,
              country: "US",
            },
          },
        };

        let token;
        paysafeInstance.tokenize(vaultConfig, (instance, error, result) => {
          if (error) {
            setPaymentError({
              ...paymentErrors.declinedCard,
              details:
                error.code === "9003" && error.message.includes("expiry year")
                  ? "Card Expired"
                  : error.displayMessage,
            });
            setStep(paymentSteps.Error);
          } else {
            token = result.token;
            updateExistingCard(true, paymentDetails, token).subscribe(
              (card) => {
                onComplete(card);
              },
              (error) => {
                const { code, message } = error.data.errors;
                setPaymentError({
                  ...paymentErrors.declinedCard,
                  details: code === "7503" ? "Card already exists" : message,
                });
                setStep(paymentSteps.Error);
                setLoadingComplete();
              }
            );
          }
        });
      } else {
        updateExistingCard(false, paymentDetails).subscribe(
          (card) => {
            onComplete(card);
          },
          () => setLoadingComplete()
        );
      }
    } else if (paymentMethod.type === "savedACH") {
      updateExistingAccount(achData.editMode, paymentDetails).subscribe(
        (bankAccount) => {
          onComplete(bankAccount);
        },
        () => setLoadingComplete()
      );
    }
  };

  const handleDeletePaymentMethod = (onComplete) => {
    if (hasAdyen) {
      deleteAdyenPaymentMethod(paymentMethod.id).subscribe(() => {
        onComplete();
        successToast(
          `${
            paymentMethod.type.includes("CreditCard")
              ? featureEnabled("toggle_gs_display_debit_type")
                ? "Credit/debit card"
                : "Credit card"
              : "Bank account"
          } has been deleted`
        );
      });
    } else {
      let deleteMethod;
      if (paymentMethod.type.includes("CreditCard")) {
        deleteMethod = deleteCard;
      } else {
        deleteMethod = deleteBankAccount;
      }

      deleteMethod(paymentMethod.id).subscribe(() => {
        onComplete();
        successToast({
          message: `${
            paymentMethod.type.includes("CreditCard")
              ? "Credit card"
              : "Bank account"
          } has been deleted`,
        });
      });
    }
  };

  const handleResetPaymentMethod = (resetCreditCardAchFields) => {
    setPaymentMethod(defaultPaymentMethod);
    creditCardDataChanged(ccDefault);
    achDataChanged(achDefault);
    setInvalidFields([]);
    setShowCreateEditPaymentMethod(false);
    setSaveMethod(false);
    setMakeDefault(false);
    setSavedMethod(false);
    setSavedMethodName("");

    if (resetCreditCardAchFields) {
      setStep(0);
      setPaymentError(paymentErrors.declinedCard);
    }
  };

  const handleReset = (delay = 0, callback) => {
    setTimeout(() => {
      setStep(0);
      setAdditionalPayment("");
      setPrevAdditionalPayment("");
      setAdditionalPaymentEnabled(false);
      setPaymentMethod(defaultPaymentMethod);
      setPaymentNumber();
      setPaymentDate(DateTime.local().startOf("day"));
      setCreditNumber();
      setPaymentError("");
      setPaysafeInstance();
      creditCardDataChanged(ccDefault);
      achDataChanged(achDefault);
      setInvalidFields([]);
      setShowViewPaymentMethod(false);
      setShowCreateEditPaymentMethod(false);
      setSavedMethod(false);
      setSavedMethodName("");
      setSurchargeFee();
      callback && callback();
    }, delay);
  };

  const handleValidate = (e) => {
    const { id, value } = e.target;

    let invalid = [...invalidFields];
    pull(invalid, id);

    if (!value?.length) {
      invalid.push(id);
    }

    if (id === "name" && paymentMethod.type.includes("ACH")) {
      if (achData.firstName === "" || achData.lastName === "") {
        invalid.push(id);
      }
    } else if (id === "zip" && value.length !== 5) {
      invalid.push(id);
    } else if (
      id === "routingNumber" &&
      (value.length !== 9 ||
        !routingNumValidator.ABARoutingNumberIsValid(value))
    ) {
      invalid.push(id);
    } else if (id === "accountNumber" && value.length < 4) {
      invalid.push(id);
    } else if (
      id === "state" &&
      (!value || !states.map((s) => s.value).includes(value))
    ) {
      invalid.push(id);
    }

    if (achData.accountNumber.length && achData.confirmAccountNumber.length) {
      if (achData.accountNumber !== achData.confirmAccountNumber) {
        invalid.push("confirmAccountNumber");
      } else {
        pull(invalid, "confirmAccountNumber");
      }
    }

    setInvalidFields(invalid);
  };

  const clearValidationError = (e) => {
    setInvalidFields(pull([...invalidFields], e.target.id));
  };

  const editRecurrencePayment = (newSavedMethod) => {
    const selectedInvoices = invoices
      ?.filter((invoice) => invoice.selected)
      ?.map((invoice) => ({
        amount: invoice.amountToPay.toString(),
        relationships: {
          applied_to: { id: invoice.id, type: "invoice" },
        },
      }));

    let recurrences = {
      start_date: paymentDate?.toISODate(),
      template: {
        card_id: paymentMethod?.id || newSavedMethod,
        invoice_payments: selectedInvoices,
        relationships: {
          for: {
            id: clientId,
          },
        },
        amount: paymentTotal,
      },
    };

    saveScheduledPayment(paymentId, { recurrences }).subscribe(
      () => {
        setAdyenSessionDetails();
        setStep(paymentSteps.Receipt);
      },
      (error) => {
        handleError(error);
        setPaymentError({
          ...paymentErrors.failedGeneric,
          details:
            error?.data?.errors?.message || error?.data?.errors?.userMessage,
        });
        setStep(paymentSteps.Error);
      }
    );
  };

  const submitPayment = () => {
    if (hasAdyen) {
      if (isScheduledPayment && !adyenInstance) {
        editRecurrencePayment();
        return;
      }

      const paymentData = {
        ...(adyenInstance && {
          payment_data: { ...adyenInstance, storePaymentMethod: savedMethod },
        }),
        ...(savedMethod && {
          nickname: savedMethodName.trim(),
          is_preferred: makeDefault,
        }),
        ...(isFuturePayment && { payment_date: paymentDate.toISODate() }),
        ...(paymentMethod.id && { card_id: paymentMethod.id }),
        team_can_use: paymentMethod.authorizedUse,
      };

      processPaymentSession(
        adyenSessionDetails.payment_session_id,
        paymentData
      ).subscribe(
        (res) => {
          if (isScheduledPayment) {
            editRecurrencePayment(res.card_id);
            return;
          }
          setPaymentTotal((prevState) => prevState + res.surcharge_fee);
          setAdyenSessionDetails();
          setPaymentNumber(res.payment_number);
          setCreditNumber(res.credit_number);
          setStep(paymentSteps.Receipt);
          invalidateEntity("paymentHistory");
          invalidateEntity("upcomingPayments");
          invalidateEntity("scheduledPayment");
          invalidateEntity("paymentMethods");
          invalidateEntity("invoices");
        },
        (error) => {
          handleError(error);
          setPaymentError({
            ...paymentErrors.failedGeneric,
            details:
              error?.data?.errors?.message || error?.data?.errors?.userMessage,
          });
          setStep(paymentSteps.Error);
        }
      );
      return;
    }

    if (
      paymentMethod.type === "newCreditCard" ||
      (paymentMethod.type === "savedCreditCard" && isExpiredCard(paymentMethod))
    ) {
      const vaultConfig = {
        vault: {
          holderName: creditCardData.name,
          billingAddress: {
            street: creditCardData.street,
            street2: creditCardData.street2,
            city: creditCardData.city,
            state: creditCardData.state,
            zip: creditCardData.zip,
            country: "US",
          },
        },
      };

      let token;
      paysafeInstance.tokenize(vaultConfig, (instance, error, result) => {
        if (error) {
          setPaymentError({
            ...paymentErrors.declinedCard,
            details:
              error.code === "9003" && error.message.includes("expiry year")
                ? "Card Expired"
                : error.displayMessage,
          });
          setStep(paymentSteps.Error);
        } else {
          token = result.token;
          maybeSaveCard(paymentDetails, token)
            .then((card) => {
              prepareAndCreatePayment(
                { ...paymentDetails, token, card },
                clientUsers
              )
                .then((response) => {
                  setPaymentNumber(response.payment_number);
                  setCreditNumber(response.payment_credit?.credit_number);
                  setStep(paymentSteps.Receipt);
                })
                .catch((error) => {
                  setPaymentError({
                    ...paymentErrors.declinedCard,
                    details:
                      get(error, "data.errors.message", "") ||
                      get(error, "data.errors.userMessage"),
                  });
                  setStep(paymentSteps.Error);
                });
            })
            .catch((error) => {
              setPaymentError({
                ...paymentErrors.saveCard,
                details:
                  get(error, "data.errors.message", "") ||
                  get(error, "data.errors.userMessage"),
              });
              setStep(paymentSteps.Error);
            });
        }
      });
    } else if (paymentMethod.type === "newACH") {
      maybeSaveAccount(paymentDetails)
        .then((bankAccount) => {
          prepareAndCreatePayment(
            { ...paymentDetails, bankAccount },
            clientUsers
          )
            .then((response) => {
              setPaymentNumber(response.payment_number);
              setCreditNumber(response.payment_credit?.credit_number);
              setStep(paymentSteps.Receipt);
            })
            .catch((error) => {
              setPaymentError({
                ...paymentErrors.failedGeneric,
                details:
                  error?.data?.errors?.message ||
                  error?.data?.errors?.userMessage,
              });
              setStep(paymentSteps.Error);
            });
        })
        .catch((error) => {
          setPaymentError({
            ...paymentErrors.saveBank,
            details:
              error?.data?.errors?.message || error?.data?.errors?.userMessage,
          });
          setStep(paymentSteps.Error);
        });
    } else if (paymentMethod.type.includes("saved")) {
      prepareAndCreatePayment(paymentDetails, clientUsers)
        .then((response) => {
          setPaymentNumber(response.payment_number);
          setCreditNumber(response.payment_credit?.credit_number);
          setStep(paymentSteps.Receipt);
        })
        .catch((error) => {
          setPaymentError({
            ...paymentErrors.failedGeneric,
            details:
              error?.data?.errors?.message || error?.data?.errors?.userMessage,
          });
          setStep(paymentSteps.Error);
        });
    }
  };

  const handleDeletePayment = () => {
    deleteScheduledPayment(payment.id).subscribe(() => {
      successToast("The scheduled payment has been canceled");
      window.dispatchEvent(new CustomEvent("billing-ui::payment-complete"));
    });
  };

  const paymentDetails = {
    clientId,
    paymentId,
    invoices,
    additionalPayment,
    additionalPaymentEnabled,
    paymentNumber,
    paymentTotal,
    paymentDate,
    creditNumber,
    isFuturePayment,
    isScheduledPayment,
    savedPaymentMethods,
    paymentMethod,
    creditCardData,
    achData,
    saveCard: saveMethod,
    makeDefault,
    adyenInstance,
    adyenSessionDetails,
    savedMethod,
    savedMethodName,
    surchargeFee,
    shouldSurcharge,
  };

  const status = {
    loadingClientUsers,
    loadingInvoices,
    loadingPaymentMethods,
    clientUsersError,
    invoiceError,
    paymentMethodError,
    paymentError,
    validCardTypes,
    invalidFields,
    showViewPaymentMethod,
    showCreateEditPaymentMethod,
  };

  const actions = {
    nextStep: handleNextStep,
    prevStep: handlePrevStep,
    updateInvoices: handleUpdateInvoices,
    updateAdditionalPayment: handleUpdateAdditionalPayment,
    updatePaymentDate: handleUpdatePaymentDate,
    updatePaymentMethod: handleUpdatePaymentMethod,
    updatePaymentSession: handlePaymentSession,
    updatePaysafeInstance: handleUpdatePaysafeInstance,
    updateCCData: creditCardDataChanged,
    updateACHData: achDataChanged,
    validate: handleValidate,
    clearValidationError,
    forceUpdate,
    setShowViewPaymentMethod,
    setShowCreateEditPaymentMethod,
    setAdyenSessionDetails,
    setAdyenInstance,
    setSavedMethod,
    setSavedMethodName,
    setSurchargeFee,
    toggleAdditionalPayment: handleToggleAdditionalPayment,
    toggleSaveCard: setSaveMethod,
    toggleMakeDefault: setMakeDefault,
    updateNickname: handleUpdateNickname,
    authorizeUse: handleAuthorizeUse,
    savePaymentMethod: handleSavePaymentMethod,
    deletePaymentMethod: handleDeletePaymentMethod,
    resetPaymentMethod: handleResetPaymentMethod,
    deletePayment: handleDeletePayment,
    reset: handleReset,
    refetchClientUsers,
    refetchInvoices,
    refetchPaymentMethods,
  };

  return { step, paymentDetails, status, actions, paysafeInstance };
}

export function usePaymentMethods(clientId, show = false) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: genQueryKey("paymentMethods", { clientId: Number(clientId) }),
    queryFn: () =>
      fetcher(`/api/card_management/client/${clientId}`).then(
        ({ achs, cards }) => {
          return [
            ...cards.map((card) => ({
              ...card,
              type: "savedCreditCard",
              cardType: get(paysafeCardTypes, card.card_type),
              authorizedUse: card.team_can_use,
            })),
            ...achs.map((account) => ({
              ...account,
              type: "savedACH",
              authorizedUse: account.team_can_use,
            })),
          ];
        }
      ),
    staleTime: 1000 * 60 * 15,
    enabled: !!clientId && !show,
  });

  return {
    results: data || [],
    loading: isLoading,
    error,
    resubscribe: refetch,
  };
}

export const usePaysafe = (onPaysafeInstance, identifier, onChange) => {
  const [cardType, setCardType] = useState("");
  const [cardNumberInvalid, setCardNumberInvalid] = useState(false);
  const [expiryDateInvalid, setExpiryDateInvalid] = useState(false);
  const [cvvInvalid, setCvvInvalid] = useState(false);

  const paysafe_api_key = isProd()
    ? "U1VULTMyMDk3NDpCLXAxLTAtNWNmOTUzOTctMC0zMDJjMDIxNDdjMDQwNzA1ZGYzZjg3YmEzMjljYTY2OGEyNjExZjZiZjhiOTA0OWUwMjE0MzhkMTJjNGMxNGJlOGI2YzA4YmY2ZjNjOGRjOTBmODY1YWExMzJhZg=="
    : "U1VULTI4MTAyMDpCLXFhMi0wLTVjMzhmYzQ3LTAtMzAyYzAyMTQwMmM4Njc1NWY2MmE2YjgxZTIwYzQ3YjEwZDBmM2IzZGZiYzFlYTg4MDIxNDRhNDdjM2Y5MGIxODUzZGY0ZWIxZDA3ZGI5ZWEwMzE5ZmE2M2RmYjY=";

  useEffect(() => {
    if (!window.paysafe) return;

    window.paysafe.fields.setup(
      paysafe_api_key,
      {
        environment: isProd() ? "LIVE" : "TEST",
        fields: {
          cardNumber: {
            selector: `#${identifier}-card-number`,
          },
          expiryDate: {
            selector: `#${identifier}-expiration-date`,
            placeholder: "MM/YY",
          },
          cvv: {
            selector: `#${identifier}-cvv`,
          },
        },
        style: {
          input: {
            "font-family":
              '"Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif',
            "font-weight": "normal",
            "font-size": "14px",
            color: "#555",
          },
        },
      },
      (instance, error) => {
        if (!error) {
          onPaysafeInstance(instance);

          instance
            .fields("cardNumber expiryDate cvv")
            .on("Valid Invalid Focus", handlePaysafeFieldChanged);
          instance
            .fields("cardNumber expiryDate cvv")
            .on("Blur", handlePaysafeFieldChanged);
          instance.fields("cardNumber").on("FieldValueChange", () => {
            setCardType(instance.getCardBrand());
          });
        }
      }
    );
  }, [window.paysafe]);

  const handlePaysafeFieldChanged = (instance, event) => {
    switch (event.target.fieldName) {
      case "CardNumber": {
        setCardNumberInvalid(
          event.type === "Invalid"
            ? true
            : event.type === "Blur"
            ? !instance.fields.cardNumber.isValid()
            : false
        );
        break;
      }
      case "ExpiryDate": {
        setExpiryDateInvalid(
          event.type === "Invalid"
            ? true
            : event.type === "Blur"
            ? !instance.fields.expiryDate.isValid()
            : false
        );
        break;
      }
      case "Cvv": {
        setCvvInvalid(
          event.type === "Invalid"
            ? true
            : event.type === "Blur"
            ? !instance.fields.cvv.isValid()
            : false
        );
        break;
      }
    }
    onChange && onChange(instance.areAllFieldsValid());
  };

  const errors = {
    cardNumberInvalid,
    expiryDateInvalid,
    cvvInvalid,
  };

  return { cardType, errors };
};
